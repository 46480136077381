// import './style.scss';
import Logo from '../img/logo.png';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//feather-icon

// import {Menu} from "react-feather";

import { Link } from 'react-router-dom';

//contact modal

import ContactModal from '../../Modals/ContactModal';


const HorizontalNav = ({ isSidebarActive, setSidebarIsActive }) => {
    const [isActive, setIsActive] = useState(false);
    const [isActiveNav, setIsActiveNav] = useState('/');
    const [showContactModal, setShowContactModal] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate();

    const handleMenuClick = () => {
        setSidebarIsActive();
        // setIsActive(!isActive);

    };

    const scrollToTop = (link) => {
        setIsActiveNav(link)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const toggleContactModal = () => {
        // setShowContactModal(!showContactModal);
        window.open("https://calendly.com/akash-insightai/30min", "_blank");
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={`navigation ${isScrolled ? 'scrolled' : ''}`}>
            <div className="container">
                <Link to="/" className="logo" onClick={() => scrollToTop('/')}>
                    <img src={Logo} alt="Insight Ai" className="main-logo" />
                </Link>

                <div className="navigation__nav-items">
                    <div className='navigation__nav'>

                        <Link
                            to="/"
                            className={isActiveNav === '/' ? 'nav-item active' : 'nav-item'}
                            onClick={() => scrollToTop('/')}
                        >
                            Home
                        </Link>
                        <Link
                            to="/about-us"
                            className={isActiveNav === '/about-us' ? 'nav-item active' : 'nav-item'}
                            onClick={() => scrollToTop('/about-us')}
                        >
                            About Us
                        </Link>
                        {/* <Link
                        to="/solutions"
                        className={isActiveNav === '/solutions' ? 'nav-item active' : 'nav-item'}
                        onClick={() => scrollToTop('/solutions')}
                    >
                        Solutions
                    </Link> */}
                        <Link
                            to="/blogs"
                            className={isActiveNav === '/blogs' ? 'nav-item active' : 'nav-item'}
                            onClick={() => scrollToTop('/blogs')}
                        >
                            Blogs
                        </Link>
                        {/* <Link
                        to="/ecommerce"
                        className={isActiveNav === '/ecommerce' ? 'nav-item active' : 'nav-item'}
                        onClick={() => scrollToTop('/ecommerce')}
                    >
                        Ecommerce
                    </Link> */}
                        <Link
                            to="/faqs"
                            className={isActiveNav === '/faqs' ? 'nav-item active' : 'nav-item'}
                            onClick={() => scrollToTop('/faqs')}
                        >
                            FAQs
                        </Link>
                        {/* <Link
                        to="/events"
                        className={isActiveNav === '/events' ? 'nav-item active' : 'nav-item'}
                        onClick={() => scrollToTop('/events')}
                    >
                        Events
                    </Link> */}
                        <Link
                            to="/contact"
                            className={isActiveNav === '/contact' ? 'nav-item active' : 'nav-item'}
                            onClick={() => scrollToTop('/contact')}
                        >
                            Contact
                        </Link>
                    </div>
                    <button className='btn-dark nav-btn' data-text="Book a Demo" onClick={toggleContactModal}><span className='btn-wrapper'>Book a Demo</span></button>
                    {/* Menu Button */}
                    <button className={`hamburger ${isSidebarActive ? 'active' : ''}`} onClick={handleMenuClick}><span className='menu-line'></span></button>
                </div>


            </div>
            <ContactModal showContactModal={showContactModal} toggleContactModal={toggleContactModal} />
        </nav>
    )
}

export default HorizontalNav;